<template>
  <div>
    <form @submit.prevent="createFaq" class="relative">
      <div v-show="isBusy" class="absolute inset-0 bg-faint-white flex items-center justify-center z-20">
        <spinner :color="'text-black'" :size="10" />
      </div>
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">Category
          <span class="text-red-500">*</span>
        </label>
        <div class="w-full relative">
          <select class="bge-input bge-select rounded" v-model="faq.category">
            <option v-for="category in categories" :key="category" :value="category">
              {{ category }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">FAQ Title
          <span class="text-red-500">*</span>
        </label>
        <input v-model="faq.title" required class="bge-input bge-input-spacing w-full rounded" />
      </div>
      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">Products</label>
        <AutoComplete forceSelection v-model="selectedProduct" field="product_code" :suggestions="filteredProducts"
          @complete="searchDocument($event)" @item-select="addItemToTable" placeholder="Type here to search the products"
          class="w-full">
          <template #item="{ item, index }">
            <div class="autocomplete-row">
              <div class="autocomplete-code">{{ item.product_code }}</div>
              <div class="autocomplete-name" :title="item.name">
                {{ item.name }}
              </div>
            </div>
          </template>
        </AutoComplete>
      </div>
      <div class="w-full pb-2" v-if="selectedItems.length > 0">
        <DataTable stripedRows :value="selectedItems" :paginator="selectedItems.length > 5" :rows="5"
          responsiveLayout="scroll" paginatorTemplate="CurrentPageReport PrevPageLink PageLinks NextPageLink  "
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" class="no-header"
          columnResizeMode="fit">
          <Column field="product_code" header="Product Code" className="product-code-column"></Column>
          <Column field="name" header="Product Name" className="product-name-column"></Column>
          <Column className="action-column">
            <template #body="slotProps">
              <Button icon="pi pi-trash" @click="deleteItem(slotProps.data)"></Button>
            </template>
          </Column>
        </DataTable>
      </div>

      <div class="w-full mb-2">
        <label class="uppercase font-semibold text-sm">Visiblity
          <span class="text-red-500">*</span>
        </label>
        <div class="w-full relative">
          <select class="bge-input bge-select rounded" v-model="faq.visibility">
            <option v-for="visibility in visiblityOptions" :key="visibility" :value="visibility">
              {{ visibility }}
            </option>
          </select>
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
            <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
              <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
            </svg>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <label class="uppercase font-semibold text-sm">Description</label>
        <textarea class="w-full bge-input bge-input-spacing rounded" v-model="faq.description" rows="5" />
      </div>
      <div class="mb-2">
        <label class="uppercase font-semibold text-sm">resolution</label>
        <textarea class="w-full bge-input bge-input-spacing rounded" v-model="faq.resolution" rows="5" />
      </div>
      <div class="relative flex justify-end mt-5">
        <div v-show="!canSubmitForm" class="opacity-50 bg-white absolute inset-0"></div>
        <button :disabled="isBusy || !canSubmitForm" type="submit"
          class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100">
          <svg class="stroke-current h-5 w-5 mr-2" fill="none" stroke-width="2" stroke-linecap="round"
            stroke-linejoin="round" viewBox="0 0 24 24">
            <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
            <path d="M17 21v-8H7v8M7 3v5h8" />
          </svg>
          <span>Save</span>
        </button>
      </div>
    </form>
    <portal to="overlay-outlet">
      <confirm-alert :showingConfirm="confirmAlert.showing" :message="confirmAlert.message"
        @complete="confirmAlert.resultFunction" />
    </portal>
  </div>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");
import ConfirmAlert from "@/components/ConfirmAlert.vue";

export default {
  name: "CreateFaq",
  components: {
    Spinner,
    Toggle,
    Datepicker,
    ConfirmAlert
  },
  props: {
    providedContact: {
      type: Object,
    },
    faqId: {
      type: String,
    },
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      isBusy: false,
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      customersEquipment: [],
      categories: [],
      visiblityOptions: [],
      selectedItems: [],
      filteredProducts: null,
      selectedProduct: null,
      faq: {},
      confirmAlert: {
        resultFunction: "",
        showing: false,
        message: "",
        title: "",
        data: "",
      }
    };
  },
  computed: {
    canSubmitForm() {
      return this.faq.category && this.faq.visibility && this.faq.title;
    },
  },
  mounted() {
    this.loadCategories();
    this.loadVisiblityOptions();
    if (this.isEdit)
      this.loadProducts();
  },
  methods: {
    async searchDocument(event) {
      try {
        let result = await this.ProductService.getProducts(
          event.query.toLowerCase(),
        );
        this.filteredProducts = result;
      } catch (error) {
        console.error(error);
      }
    },
    loadCategories: async function () {
      let result = await this.faqService.loadCategories();
      this.categories = result;
    },
    loadVisiblityOptions: async function () {
      let result = await this.faqService.loadVisiblityOptions();
      this.visiblityOptions = result;
    },
    loadProducts: async function () {
      this.isBusy = true;
      try {
        const faqData = await this.faqService.getFaqById(this.faqId);
        this.faq = faqData;
        this.selectedItems = this.faq.products;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
    createFaq: async function () {
      this.isBusy = true;
      try {
        let result = await this.faqService[
          this.isEdit ? "editFaq" : "createFaq"
        ]({
          ...this.faq,
          products: this.selectedItems.map((e) => e.product_code) || [],
        });
        this.isBusy = false;
        this.$emit("complete");
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Success",
                  close: onClose,
                },
              },
              `FAQ ${this.isEdit ? "updated" : "created"} successfully.`,
            );
          },
          {
            position: "top-right",
          },
        );
      } catch (error) {
        console.error(error);
        this.isBusy = false;
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There's been an error whilst logging this service ticket.",
            );
          },
          {
            position: "top-right",
          },
        );
      }
    },
    addItemToTable() {
      if (
        this.selectedProduct &&
        this.selectedItems.filter(
          (e) => e.product_code === this.selectedProduct.product_code,
        ).length == 0
      ) {
        this.selectedItems.push(this.selectedProduct);
      }
      this.selectedProduct = null;
    },
    deleteProductById: function (result) {
      if (result) {
        const index = this.selectedItems.indexOf(this.confirmAlert.data);
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
      this.confirmAlert = {
        resultFunction: "",
        message: "",
        showing: false,
        data: "",
      };
    },
    deleteItem(item) {
      this.confirmAlert = {
        resultFunction: this.deleteProductById,
        message:
          "Are you sure you want to delete this product? Cannot be undone",
        showing: true,
        data: item,
      };
    },
  },
};
</script>

<style>
::v-deep .p-datatable-thead {
  display: none;
}

.autocomplete-row {
  display: flex;
  justify-content: space-between;
}

.autocomplete-code,
.autocomplete-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.autocomplete-code {
  width: 25%;
}

.autocomplete-name {
  width: 75%;
}

.p-autocomplete-panel {
  max-width: 100%;
  width: auto;
}

.p-autocomplete-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.no-header .p-datatable-thead {
  display: none;
}

.product-code-column {
  width: 20%;
}

.product-name-column {
  width: 70%;
}

.action-column {
  width: 10%;
}
</style>
